<template>
  <div class="page-wrapper">
    <HeaderBlock></HeaderBlock>
    <div class="content-scroll">
      <main class="view">
        <div class="not-found">
          <div class="card col col-5">
            <h1 class="center">(404) Page Not Found</h1>
            <button @click="GoToPreviousPage" class="big">Previous page</button>
          </div>
        </div>
      </main>
      <FooterBlock></FooterBlock>
    </div>
  </div>
</template>

<script>
  import HeaderBlock from '@/components/blocks/Header';
  import FooterBlock from '@/components/blocks/Footer';

  export default {
    name: 'NotFound',
    components: {
      'HeaderBlock': HeaderBlock,
      'FooterBlock': FooterBlock
    },
    data() {
      return {

      }
    },
    created() {
      
    },
    mounted() {
      
    },
    destroyed() {
      
    },
    computed: {
      
    },
    methods: {
      GoToPreviousPage() {
        window.history.go(-1);
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/style/variables/colors';
  @import '../assets/style/variables/fonts';
  //@import '../assets/style/variables/icons';

  .view {
    height: calc(100% - 33px);
    
    .not-found {
      width: 100%;
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;

      .card {
        height: auto;
        padding: 30px;
        display: flex;
        min-height: 200px;
        position: relative;
        flex-direction: column;
        background-color: $white;
        justify-content: space-between;
        box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.1);

        h1 {
          line-height: 1;
          
          &.center {
            text-align: center;
          }
        }

        button {
          border: 0;
          outline: none;
          appearance: none;

          &.big {
            margin: 0 auto;
            padding: 15px 30px;
            color: $white;
            font-size: $f-xl;
            border-radius: 12px;
            background-color: $blue;
          }
        }
      }
    }
  }
</style>